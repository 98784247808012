#artweek {
    background-color: $blue;
    text-align: center;
    min-height: 100vh;
    
    img {
        width: 450px;
        margin: 180px 0 50px 0;
        
        @include mobile {
            width: 350px;
            margin-top: 50px;
        }
        @include small-mobile {
            width: 300px;
            margin-top: 50px;
        }
    }
    p {
        max-width: 65%;
        text-align: center;
        margin: 0 auto;
        
        @include tablet {
            max-width: 75%;
        }
        @include mobile {
            max-width: 85%;
        }
        @include small-mobile {
            max-width: 85%;
        }
        
        a {
            font-weight: 500;
        }
    }
}