#gallery {
    
    .tileGrid {
        background: $blue;
        //min-height: 100vh;
        text-align: center;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-evenly;

        .tile {
            position: relative;
            flex-grow: 1;
            width: 33.33vw;
            min-height: 385px;
            background-size: cover; 
            background-position: center;
            background-repeat: no-repeat;
            text-align: center;
            
            &.large {
                width: 50vw;
                
                @include tablet {
                    width: 50vw;
                }
                @include mobile {
                    width: 50vw;
                }
                @include small-mobile {
                    width: 100vw;
                }
            }
            
            &.full {
                width: 100vw;
            }
            
            @include tablet {
                width: 50vw;
            }
            @include mobile {
                width: 50vw;
            }
            @include small-mobile {
                width: 100vw;
            }
            
            .tileContent {
                background: rgba(37, 51, 75, 0.95);
                width: 100%;
                height: 100%;
                position: absolute;
                padding: 170px 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 25;
                transition: $transition;
                opacity: 0;
                
                &:hover {
                    opacity: 1;
                }
                h2 {
                    margin-bottom: 10px;
                }
            }
        }
    }
    
    #wip {
        background: $darkBlue;
        text-align: center;
        padding: 40px 0;
        
        h3 {
            font-size: 30px;
            
            @include mobile {
                font-size: 28px;
            }
            @include small-mobile {
                font-size: 26px;
            }
            
            span {
                color: $orange;
                letter-spacing: -.15em;
                margin-right: 10px;
            }
        } 
    }
    
    #context {
        background: $blue;
        text-align: center;
        padding: 150px 0 130px 0;
        
        h2 {
            font-size: 25px;
            color: $white;
            letter-spacing: .08em;
            text-transform: uppercase;
        }
        h1 {
            color: $orange;
            letter-spacing: .08em;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
        p {
            line-height: 1.3em;
            max-width: 75%;
            margin: 0 auto 25px auto;
            
            @include tablet {
                max-width: 80%;
            }
            @include mobile {
                max-width: 85%;
            }
            @include small-mobile {
                max-width: 90%;
            }
            
            a {
                opacity: .5;
                
                &:hover {
                    opacity: 1;
                }
            }
            
            span {
                font-weight: 600;
            }
        }
    }
}