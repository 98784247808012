.baseButton { // only for extending
    width: 325px;
    padding: 14px 0;
    margin: 50px auto 180px auto;
    border: 2px dashed rgba(255, 255, 255, 0.5);
    font-family: $typeface; 
    color: $white;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .2em;
    text-transform: uppercase;
    text-align: center;
    display: block;
    transition: $transition;
    
    @include mobile {
        margin-bottom: 50px;
    }
    @include small-mobile {
        margin-bottom: 50px;
    }
    
    &:hover {
        color: $orange;
        border: 2px dashed rgba(255, 255, 255, 1);
    }
}