/*---------------
  GLOBAL
---------------*/
body {
    margin: 0;
    padding: 0; 
    width: 100%;
    height: 100%;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
} 
img {
    border: 0;
    max-width: 100%;
}
a {
    text-decoration: none!important;
    outline: none;
    transition: $transition;
}
button {
    text-decoration: none!important;
    outline: none;
}
.contain {
	position: relative;
}
.clear {
    clear: both;
}
::-moz-selection {
    background: $darkBlue;
	color: $white;
}
::selection {
    background: $darkBlue;
	color: $white;
}