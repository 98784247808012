#nav {
    background: $white;
    height: 110px;
    box-shadow: $shadow;
    
    .leftNav {
        width: 150px;
        position: absolute;
        top: 35px;
        left: 0;
        text-align: left;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin-right: 30px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
    .logo {
        width: 275px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 25px;
    }
    .rightNav {
        width: 150px;
        position: absolute;
        top: 35px;
        right: 0;
        text-align: right;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin-left: 30px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
    
    .mobileNav {
        width: 100%;
        position: absolute;
        top: 130px;
        right: 0;
        left: 0;
        text-align: center;
        z-index: 900;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin: 0 15px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
}