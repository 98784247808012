#error {
    background: $blue;
    min-height: 100vh;
    
    h1 {
        text-align: center;
        margin-top: 18%;
        font-weight: 700;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 22px;
        font-weight: 500;
        color: $orange;
        transition: $transition;
        text-align: center;
        
        &:hover {
            color: $white
        }
    }
    a {
        color: $orange;
        
        &:hover {
            color: $white
        }
    }
}