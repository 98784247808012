/*---------------
<<<<<<< HEAD
  FONTS
---------------*/   
$typeface: 'Overpass', sans-serif;
   
/*---------------                    
  COLORS
---------------*/   
$blue: #25334B;
$darkBlue: #1E2738;
$orange: #F06E47;
$white: #FFF;

/*---------------
  MEDIA QUERIES
---------------*/
$mobile-width: 480px;
$tablet-width: 768px;
$small-desktop-width: 992px;
$desktop-width: 1200px;
$large-desktop-width: 1440px;

/*---------------
  GENERAL
---------------*/
$shadow: 1px 1px 3px 0 rgba(0,0,0,0.1);
$transition: all .5s ease-in-out;