#header {
    background: $white;
    height: 220px;
    box-shadow: $shadow;
    position: relative;
    z-index: 992;
    
    .leftNav {
        width: 150px;
        position: absolute;
        top: 80px;
        left: 0;
        text-align: left;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin-right: 30px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
    .logo {
        width: 450px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 60px;
        
        @include mobile {
            width: 350px;
            top: 30px;
        }
        @include small-mobile {
            width: 350px;
            top: 30px;
        }
    }
    .rightNav {
        width: 150px;
        position: absolute;
        top: 80px;
        right: 0;
        text-align: right;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin-left: 30px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
    
    .mobileNav {
        width: 100%;
        position: absolute;
        top: 130px;
        right: 0;
        left: 0;
        text-align: center;
        
        a {
            width: 40px;
            height: 40px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin: 0 15px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 20px;
                position: relative;
                top: 8px;
            }
        }
    }
}