h1,h2,h3,h4,h5,h6,p {
    margin: 0; 
    padding: 0; 
}
h1 { // Titles
    font-family: $typeface;       
  	font-size: 60px;
  	color: $white;         
  	font-weight: 300;
    line-height: 1.2em;
    
    @include mobile {
        font-size: 50px;
    }
    @include small-mobile {
        font-size: 50px;
    }
}   
h2 { //Banner Dates 
    font-family: $typeface; 
    font-size: 26px;
    color: $orange;
    font-weight: 300; 
    line-height: 1.2em;
} 
h3 { // Card Titles
    font-family: $typeface; 
    font-size: 20px;
    color: $white;
    font-weight: 300;
    line-height: 1.2em;
}
h4 { // Buttons
    font-family: $typeface;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2em;
}
p {
    font-family: $typeface;
    color: $white;
    font-size: 28px;
    font-weight: 200;
    line-height: 1.4em;
    
    @include mobile {
        font-size: 24px;
        line-height: 1.3em;
    }
    @include small-mobile {
        font-size: 22px;
        line-height: 1.3em;
    }
}
b {
    font-weight: 600;
}
strong {
    font-weight: 600;
}
a:link {
    color: $white;
}
a:visited {
    color: $white;
}
a:hover {
    color: $orange;
}
a:active {
    color: $white;
}
