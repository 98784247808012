/* BASE - Base Styles */
@import 'base/variables.scss';
@import 'base/base.scss';
@import 'base/mixins.scss';
@import 'base/typography.scss';
@import 'base/buttons.scss';

/* LAYOUTS - Layout Component Styles */
@import 'layouts/_header.scss';
@import 'layouts/_nav.scss';
@import 'layouts/_sticky.scss';
@import 'layouts/_footer.scss';

/* MODULES - Functional Components */
@import 'modules/bootstrap.css';
@import 'modules/normalize.css';
@import 'modules/animate.css';
@import 'modules/hover.css';

/* PAGES - Individuals Page Styles */
@import 'pages/_artweek.scss';
@import 'pages/_gallery.scss';
@import 'pages/_post.scss';
@import 'pages/_error.scss';