#post {
    .hero {
        background-color: $blue;
        height: 400px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        text-align: center;
        position: relative;
        
        h1 {
            font-size: 32px;
            font-weight: 700;
            color: $white;
            text-transform: uppercase;
            margin-top: 140px;
            margin-bottom: 5px;
            position: relative;
            z-index: 810;
        }
        h3 {
            font-size: 20px;
            color: $orange;
            text-transform: uppercase;
            position: relative;
            z-index: 810;
        }
        .overlay {
            background-color: $blue;
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: .95;
            z-index: 800;
            top: 0;
        }
    }
    #divider {
        margin-bottom: 60px;
        
        @include mobile {
            margin-bottom: 0;
        }
        @include small-mobile {
            margin-bottom: 0;
        }
        h4 {
            color: $orange;
            text-transform: uppercase;
            letter-spacing: .08em;
            transition: $transition;
            
            &:hover {
                color: $blue;
            }
        }
        .back {
            text-align: left;
            position: absolute;
            left: 15px;
            top: 20px;
        }
        .preview {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            border: 6px solid $white;
            box-shadow: $shadow;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto;
            display: block;
            position: relative;
            top: -80px;
            z-index: 850;
        }
        .next {
            text-align: right;
            position: absolute;
            right: 15px;
            top: 20px;
        }
    }
    #content {
        @include mobile {
            margin-bottom: 50px;
        }
        @include small-mobile {
            margin-bottom: 50px;
        }
        p {
            color: #666;
            text-align: center;
            max-width: 65%;
            margin: 0 auto 180px auto;
            
            @include tablet {
                max-width: 75%;
            }
            @include mobile {
                max-width: 85%;
                margin-bottom: 50px;
            }
            @include small-mobile {
                max-width: 90%;
                margin-bottom: 50px;
            }
        }
        a {
            color: #666;
            
            &:hover {
                color: $orange;
            }
        }
        .feature {
            margin-bottom: 180px;
            position: relative;
            
            @include mobile {
                display: block;
                max-width: 85%;
                margin: 0 auto 50px auto;
            }
            @include small-mobile {
                display: block;
                max-width: 85%;
                margin: 0 auto 30px auto;
            }
            
            img {
                display: block;
                margin: 0 auto;
                width: 1000px;
                max-width: 100%;
                position: relative;
                z-index: 900;
                border: 4px solid #FFF;
            }
            .accentA {
                height: 500px;
                width: 500px;
                border: 2px dashed #DDD;
                position: absolute;
                top: -60px;
                right: 20px;
                
                @include mobile {
                    display: none;
                }
                @include small-mobile {
                    display: none;
                }
            }
            .accentB {
                position: absolute;
                height: 500px;
                width: 500px;
                border: 2px dashed #DDD;
                bottom: -60px;
                left: 20px;
                
                @include mobile {
                    display: none;
                }
                @include small-mobile {
                    display: none;
                }
            }
        }
    }
}