#sticky{
    top: 0;
    visibility: visible;
    width: 100%;
    position: fixed;
    z-index: 900;
    margin: 0px auto;
    padding: 15px 0;
    background-color: $white;
    box-shadow: $shadow;
    
    .logo {
        width: 200px;
        display: block;
        position: relative;
        
        @include small-mobile {
            display: none;
        }
    }
    
    .nav {
        width: 100%;
        position: absolute;
        right: 0px;
        top: 4px;
        text-align: right;
        
        @include small-mobile {
            text-align: center;
            position: relative;
            left: 0px;
            top: 0px;
        }
        
        a {
            width: 36px;
            height: 36px;
            background: $blue;
            border-radius: 50%;
            display: inline-block;
            margin: 0 10px;
            text-align: center;
            
            &:hover {
                background: $orange;
            }
            
            img {
                max-height: 16px;
                position: relative;
                top: 6px;
            }
        }
    }
}